import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Tabs, Tab, Typography, Box, IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RefDataDate from './RefDataDate'

import './index.css';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Sidetab = (props) => {
  let tabs = props.tab.filter(item => !item.isHide)
  return (
    <div className={`absolute bottom-0 right-0 lg:top-0 lg:right-0 duration-700 ${props.open ? 'translate-x-0 translate-y-0' : 'translate-y-full translate-x-0 lg:translate-y-0 lg:translate-x-full'} w-screen lg:w-1/2 lg:h-[100vh] z-[1]`}>
      <div className={`flex absolute h-[40px] -mt-[40px] lg:-ml-[140px] lg:w-[240px] right-0 lg:left-0 lg:top-[220px] bg-green-800 lg:-rotate-90 p-1 px-8 lg:p-1 rounded-t-lg`}
        onClick={() => {
          props.setOpen(!props.open)
        }}>
        <svg className='ml-auto' version="1.0" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 32 32">
          <g transform="translate(0,32) scale(0.1,-0.1)" fill="#ffffff" stroke="none">
            <path d="M125 298 c-3 -8 -4 -75 -3 -149 l3 -134 35 0 35 0 0 145 0 145 -33 3 c-20 2 -34 -2 -37 -10z" />
            <path d="M230 125 l0 -116 38 3 37 3 0 110 0 110 -37 3 -38 3 0 -116z" />
            <path d="M14 146 c-3 -7 -4 -40 -2 -72 l3 -59 38 -3 37 -3 0 75 0 76 -35 0 c-21 0 -38 -6 -41 -14z" />
          </g>
        </svg>
        <p className="flex lg:hidden m-1 mr-auto text-white text-xl text-center">
          ดูข้อมูล
        </p>
        <p className="hidden lg:flex m-1 mr-auto text-white text-xl text-center">
          ขยายแผนที่
        </p>
      </div>

      <div className='w-[100%] lg:flex lg:h-[100%]'>
        <div className={`h-[100vh] w-[100%] pt-[90px] lg:pt-0 lg:w-full lg:h-[100%] z-[1] bg-[#f7f9fe] overflow-y-auto`}>
          <div className='px-1 lg:pt-[100px] text-cennter content-center'>
            <div className='lg:hidden text-right'>
              <IconButton style={{ backgroundColor: '#ef4444' }} size='medium' className='p-1 m-2' aria-label="delete" onClick={() => props.setOpen(!props.open)}>
                <CloseIcon style={{ color: 'white' }} />
              </IconButton>
            </div>
            <p className='text-center text-[#038967] font-bold mb-6 text-3xl lg:text-4xl'>{props.title}</p>
            {/* <Box className='text-center content-center' sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
            {
              tabs.length > 1 &&
              <Box display="flex" justifyContent="center" width="100%">
                <Tabs
                  variant="scrollable"
                  scrollButtons="auto"
                  value={props.tabvalue} onChange={props.handleChangeTab}
                  aria-label="basic tabs example"
                  centered
                >
                  {
                    tabs
                      .map((item, index) => {
                        return <Tab label={<span className='text-base lg:text-lg text-center font-bold'>{item.title}</span>} {...a11yProps(index)}> </Tab>
                      })
                  }
                </Tabs>
              </Box>
            }
            {
              props.tab.map((item, index) => {
                return (
                  <TabPanel value={props.tabvalue} index={index}>
                    {item.content}
                  </TabPanel>
                )
              })
            }
            <div className='m-2'>
              <RefDataDate
                title={props?.titleRef}
                subjectTitle={props?.subjectTitleRef}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Sidetab
