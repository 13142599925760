import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { Box } from '@mui/material';
import FilterKRT from './FilterKRT'

const LIST_PROVINCE = [
  "กรุงเทพมหานคร",
  "สมุทรปราการ",
  "นนทบุรี",
  "ปทุมธานี",
  "พระนครศรีอยุธยา",
  "อ่างทอง",
  "ลพบุรี",
  "สิงห์บุรี",
  "ชัยนาท",
  "สระบุรี",
  "ชลบุรี",
  "ระยอง",
  "จันทบุรี",
  "ตราด",
  "ฉะเชิงเทรา",
  "ปราจีนบุรี",
  "นครนายก",
  "สระแก้ว",
  "นครราชสีมา",
  "บุรีรัมย์",
  "สุรินทร์",
  "ศรีสะเกษ",
  "อุบลราชธานี",
  "ยโสธร",
  "ชัยภูมิ",
  "อำนาจเจริญ",
  "บึงกาฬ",
  "หนองบัวลำภู",
  "ขอนแก่น",
  "อุดรธานี",
  "เลย",
  "หนองคาย",
  "มหาสารคาม",
  "ร้อยเอ็ด",
  "กาฬสินธุ์",
  "สกลนคร",
  "นครพนม",
  "มุกดาหาร",
  "เชียงใหม่",
  "ลำพูน",
  "กระบี่",
  "ลำปาง",
  "อุตรดิตถ์",
  "แพร่",
  "น่าน",
  "พะเยา",
  "เชียงราย",
  "แม่ฮ่องสอน",
  "นครสวรรค์",
  "อุทัยธานี",
  "กำแพงเพชร",
  "ตาก",
  "สุโขทัย",
  "พิษณุโลก",
  "พิจิตร",
  "เพชรบูรณ์",
  "ราชบุรี",
  "กาญจนบุรี",
  "สุพรรณบุรี",
  "นครปฐม",
  "สมุทรสาคร",
  "สมุทรสงคราม",
  "เพชรบุรี",
  "ประจวบคีรีขันธ์",
  "นครศรีธรรมราช",
  "พังงา",
  "ภูเก็ต",
  "สุราษฎร์ธานี",
  "ระนอง",
  "ชุมพร",
  "สงขลา",
  "สตูล",
  "พัทลุง",
  "ตรัง",
  "ปัตตานี",
  "ยะลา",
  "นราธิวาส"
]

const Overview = (props) => {
  let { data, filter, setFilter,roleActive } = props;
  let total_target = props.data.reduce((acc, b) => acc + b.total_target, 0)
  let total_oosc = props.data.reduce((acc, b) => acc + b.total_oosc, 0)
  let total_all = props.data.reduce((acc, b) => acc + b.total_all, 0)
  let total_oosc3_5 = props.data.reduce((acc, b) => acc + b.oosc3_5, 0)
  let total_oosc6_11 = props.data.reduce((acc, b) => acc + b.oosc6_11, 0)
  let total_oosc12_14 = props.data.reduce((acc, b) => acc + b.oosc12_14, 0)
  let total_oosc15_17 = props.data.reduce((acc, b) => acc + b.oosc15_17, 0)

  let key_map = 'province'
  let isShow = false
  let titleTable = 'จังหวัด'
  if (props.filter.view === 'province') {
    key_map = 'city'
    isShow = true
    titleTable = 'อำเภอ'
  } else if (props.filter.view === 'city') {
    key_map = 'tumbon'
    isShow = true
    titleTable = 'ตำบล'
  }

  return (
    <div className='flex flex-col'>
      <div className='w-[full] flex justify-center'>
        <div className=' flex flex-col' >
          <div className=' bg-white flex flex-row   border-solid border-2 border-gray-200 px-2 mx-2 mb-1 rounded-lg' >
            < FilterKRT
              listprovince={LIST_PROVINCE}
              data={props.filter}
              roleActive={roleActive}
              onChange={(res) => {
                props.onUpdateFilter({ ...props.filter, ...res })
              }}
            />
          </div>
        </div>
      </div>

      <div className='pt-2 px-4 text-cennter content-center'>
        <div className='flex flex-col gap-4 md:flex-row justify-between'>
          <div className="bg-white border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full md:w-[320px] p-2 m-2">
            <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>เด็ก/เยาวชนทั้งหมด</h2>
            <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>อายุ 3-18 ปี</h2>
            <h2 className='text-md lg:text-2xl text-center text-[#038967] m-0 font-bold'>{(total_target + total_oosc).toLocaleString("en-US")} คน</h2>
          </div>
          <div className="bg-white border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full md:w-[320px] p-2 m-2">
            <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>เด็กนักเรียนในระบบการศึกษา</h2>
            <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>อายุ 3-18 ปี</h2>
            <h2 className='text-md lg:text-2xl text-center text-[#038967] m-0 font-bold'>{total_target.toLocaleString("en-US")} คน</h2>
          </div>
          <div className="bg-white border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full md:w-[320px] p-2 m-2">
            <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>เด็ก/เยาวชนที่ไม่มีข้อมูล</h2>
            <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>ในระบบการศึกษา อายุ 3-18 ปี</h2>
            <h2 className='text-md lg:text-2xl text-center text-[#038967] m-0 font-bold'>{total_oosc.toLocaleString("en-US")} คน</h2>
          </div>
        </div>
      </div>
      <Box className='text-center content-center' sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {/* <div className='pt-5 px-5 text-cennter content-center bg-white rounded-lg mt-4 m-2'> */}
        <div className="w-full bg-white border-[1px] border-solid mx-auto rounded-xl shadow-xl p-2 my-2">
          <div className='flex flex-row'>
            <div className='w-1/5'>
              {
                isShow &&
                <button class="inline-flex items-center bg-red-500 border-0 py-1 m-2 px-3 focus:outline-none hover:bg-red-700 rounded text-base mt-4 md:mt-0 text-white"
                  onClick={() => {
                    if (props.filter.view === 'province') {
                      props.onUpdateFilter({
                        ...props.filter,
                        ...{
                          view: 'country',
                          region: "ทั้งหมด",
                          province_name: "ทั้งหมด",
                          city_name: null,
                          tumbon_name: null,
                        }
                      })
                    } else if (props.filter.view === 'city') {
                      props.onUpdateFilter({
                        ...props.filter,
                        ...{
                          view: 'province',
                          region: "ทั้งหมด",
                          city_name: null,
                          tumbon_name: null,
                        }
                      })
                    }
                  }}>
                  ย้อนกลับ
                </button>
              }

            </div>
            <div className='w-3/5'>
              <p className='text-center text-[#038967] font-bold text-xl lg:text-2xl m-1'>เด็กที่ไม่มีข้อมูลในระบบการศึกษา แบ่งตามจังหวัด</p>
            </div>
            <div className='w-1/5'>
            </div>
          </div>

          <Table
            size="small"
            bordered
            scroll={{ x: 800, y: 500 }}
            style={{ width: '100%' }}
            dataSource={props.data}
            // dataSource={props.data.filter(x => {
            //   return LIST_PROVICNE.includes(x.province)
            // }).map(x => ({
            //   ...x,
            //   percent_table: ((x.sum_ecd / x.total_ecd) * 100).toFixed(2)
            // }))
            // }
            summary={() => (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>รวม</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}><span style={{ float: 'right' }}>{total_all.toLocaleString()}</span></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}><span style={{ float: 'right' }}>{total_oosc3_5.toLocaleString()}</span></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}><span style={{ float: 'right' }}>{total_oosc6_11.toLocaleString()}</span></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}><span style={{ float: 'right' }}>{total_oosc12_14.toLocaleString()}</span></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}><span style={{ float: 'right' }}>{total_oosc15_17.toLocaleString()}</span></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}><span style={{ float: 'right' }}>{total_oosc.toLocaleString()}</span></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
            columns={[
              {
                title: titleTable,
                defaultSortOrder: 'ascend',
                dataIndex: key_map,
                key: key_map,
                width: 120,
                fixed: 'left',
                align: 'center',
                render(text, record) {
                  return {
                    props: {
                      style: { textAlign: 'left' }
                    },
                    children: <div onClick={() => {

                      if (props.filter.view === 'country') {
                        props.onUpdateFilter(
                          {
                            ...props.filter,
                            ...{
                              view: 'province',
                              region: "ทั้งหมด",
                              province_name: text,
                              city_name: null,
                              tumbon_name: null,
                            }
                          })
                      } else if (props.filter.view === 'province' && roleActive !== 'public-login') {
                        props.onUpdateFilter(
                          {
                            ...props.filter,
                            ...{
                              view: 'city',
                              region: "ทั้งหมด",
                              province_name: props.filter.province_name,
                              city_name: text,
                              tumbon_name: null,
                            }
                          })
                      }
                    }}>
                      {(props.filter.view !== 'city' && roleActive !== 'public-login') || (props.filter.view !== 'province' && roleActive === 'public-login') ? <a > {text}</a> : <p className='m-0' > {text}</p>}
                    </div>
                  };
                },
                sorter: (a, b) => {
                  if (a[key_map] < b[key_map]) {
                    return -1;
                  }
                  if (a[key_map] > b[key_map]) {
                    return 1;
                  }
                  // a must be equal to b
                  return 0;
                },
              },
              {
                title: <span>เด็ก/เยาวชนทั้งหมด <br />อายุ 3-18 ปี</span>,
                dataIndex: 'total_all',
                key: 'total_all',
                align: 'center',
                render(text, record) {
                  return {
                    props: {
                      style: { textAlign: "right" }
                    },
                    children: <div>{text ? text.toLocaleString() : '0'}</div>
                  };
                },
                width: 200,
                sorter: (a, b) => a.total_target - b.total_target,
              },
              {
                title: 'เด็ก/เยาวชนที่ไม่มีข้อมูลในระบบการศึกษา',
                children: [
                  {
                    title: 'อายุ 3 -5 ปี',
                    dataIndex: 'oosc3_5',
                    key: 'oosc3_5',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text ? text.toLocaleString() : '0'}</div>
                      };
                    },
                    width: 110,
                    sorter: (a, b) => a.oosc3_5 - b.oosc3_5,
                  },
                  {
                    title: 'อายุ 6 - 11 ปี',
                    dataIndex: 'oosc6_11',
                    key: 'oosc6_11',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text ? text.toLocaleString() : '0'}</div>
                      };
                    },
                    width: 110,
                    sorter: (a, b) => a.oosc6_11 - b.oosc6_11,
                  },
                  {
                    title: 'อายุ 12 - 14 ปี',
                    dataIndex: 'oosc12_14',
                    key: 'oosc12_14',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text ? text.toLocaleString() : '0'}</div>
                      };
                    },
                    width: 110,
                    sorter: (a, b) => a.oosc12_14 - b.oosc12_14,
                  },
                  {
                    title: 'อายุ 15 - 18 ปี',
                    dataIndex: 'oosc15_17',
                    key: 'oosc15_17',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text ? text.toLocaleString() : '0'}</div>
                      };
                    },
                    width: 110,
                    sorter: (a, b) => a.oosc15_17 - b.oosc15_17,
                  },
                  {
                    title: 'รวม',
                    dataIndex: 'total_oosc',
                    key: 'total_oosc',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text ? text.toLocaleString() : '0'}</div>
                      };
                    },
                    width: 80,
                    sorter: (a, b) => a.total_oosc - b.total_oosc,
                  },
                ]
              },

            ]}
          >
          </Table>
        </div>
      </Box>
    </div >
  )
}

export default Overview
