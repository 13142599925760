import React, { useState, useEffect, useRef } from "react";
import Layoutas from "../../components/Layout";
import Mapbox from "../../mapbox";
import LegendCard from "../../mapbox/LegendCard";
import Calculate from "../../util/calculate";

// import Sidetab from "./Sidetab";
import Sidetab from '../../components/Sidetab'
import "../index.css";
import TumbonAddr from "../../util/TumbonAddr";
import Overview from "./map_oosc_contents/Overview";
// import MapAreaView from './map_cct_contents/MapAreaView'
import api from '../../util/api'
import { Modal } from 'antd';
import InfoIcon from '@mui/icons-material/Info';

const FullMap = (props) => {

  const [open, setOpen] = useState(true);
  const [tabvalue, setTabValue] = React.useState(0);
  const [initData, setInitData] = useState(null)
  const [data_map, setDataMap] = useState([]);
  const [border_color] = useState([
    "#7f1d1d",
    "#dc2626",
    "#f87171",
    "#fecaca",
    "#94a3b8",
  ]);
  const is_Click = useRef(false);
  const user_info = useRef(null)

  const [filter, setFilter] = React.useState({
    view: "country",
    year: "2566",
    region: "ทั้งหมด",
    province_name: "ทั้งหมด",
    city_name: null,
    tumbon_name: null,
    checkbox_side: [],
  });

  const [legend_data, setLegendData] = useState({
    title: "",
    subtitle: "",
    data: [],
    footer: "",
  });
  const [ismodal, setModal] = useState(false)

  useEffect(async () => {
    let res = await api.getInitData('eef_oosc')
    let user = await api.getUserInfo()
    user_info.current = user.role_active
    res.data[res.data.findIndex(x => x.city_name === "เมืองบึงกาฬ" && x.tumbon_name === "หนองเข็ง")].tumbon_name = "โนนสว่าง"
    setInitData(res.data)
  }, [])

  useEffect(() => {
    if (!initData) return null
    let data = initData;
    let response = onProcessData(
      {
        province: filter["province_name"],
        city: filter["city_name"],
        tumbon: filter["tumbon_name"],
        layer: filter.view,
      },
      convertMapContent(
        filter.view,
        data.map((x) => ({ ...x, total: 1 }))
      )
    );
    if (!filter.province_name || filter.province_name !== "ทั้งหมด") {
      if (filter.city_name !== null) {
      } else {
      }
    }
    let border_filter = response.data
    //   .map(({ total_oosc, total, student, ...other }, _) => other);
    // if (filter.checkbox_side.length > 0) {
    //   if (filter.view === "country") {
    //     border_filter = response.data.filter(
    //       (item) => !filter.checkbox_side.includes(item.province)
    //     );
    //   } else if (filter.view === "province") {
    //     border_filter = response.data.filter(
    //       (item) => !filter.checkbox_side.includes(item.city)
    //     );
    //   } else if (filter.view === "city") {
    //     border_filter = response.data.filter(
    //       (item) => !filter.checkbox_side.includes(item.tumbon)
    //     );
    //   }
    // }

    setDataMap([{ id: "border", layer: filter.view, data: border_filter }]);
    let data_legend = [
      {
        name: "จำนวนเด็กที่ไม่มีข้อมูลในระบบการศึกษาโครงการ ABE",
        value: response.data.reduce((acc, cur) => acc + cur.total_target_map_oosc, 0),
        unit: "คน",
      },

      {
        name: "จำนวนเด็กที่ไม่มีข้อมูลในระบบการศึกษาโครงการ ABE",
        value: response.data.reduce((acc, cur) => acc + cur.province20_map_oosc, 0),
        unit: "คน",
      },
    ];
    setLegendData({
      ...legend_data,
      title: filter.province_name,
      subtitle: filter.city_name,
      data: data_legend,
    });
  }, [filter, initData]);

  const convertMapContent = (layer, data) => {
    let res = [];
    let tumbon_edit = JSON.parse(JSON.stringify(TumbonAddr))
    console.log('tumbon_edit',tumbon_edit)
    console.log(tumbon_edit[tumbon_edit.findIndex(x => x.cityname === "เมืองบึงกาฬ" && x.tumbonname === "หนองเข็ง")])
    tumbon_edit[tumbon_edit.findIndex(x => x.cityname === "เมืองบึงกาฬ" && x.tumbonname === "หนองเข็ง")].tumbonname = "โนนสว่าง"
    const province = [
      ...new Set(tumbon_edit.map((item) => item.provincename)),
    ].map((item) => {
      return {
        province: item,
        total_all : 0,
        total_target: 0,
        total_oosc: 0,
        total_oosc_percent : '',
        target3_5: 0,
        target6_11: 0,
        target12_14: 0,
        target15_17: 0,
        oosc3_5: 0,
        oosc6_11: 0,
        oosc12_14: 0,
        oosc15_17: 0,
      };
    });
    const city = [
      ...new Set(
        tumbon_edit.map((item) => item.provincename + "_" + item.cityname)
      ),
    ].map((item) => {
      return {
        province: item.split("_")[0],
        city: item.split("_")[1],
        total_all : 0,
        total_target: 0,
        total_oosc: 0,
        total_oosc_percent : '',
        target3_5: 0,
        target6_11: 0,
        target12_14: 0,
        target15_17: 0,
        oosc3_5: 0,
        oosc6_11: 0,
        oosc12_14: 0,
        oosc15_17: 0,
      };
    });
    const tumbon = [
      ...new Set(
        tumbon_edit.map(
          (item) =>
            item.provincename + "_" + item.cityname + "_" + item.tumbonname
        )
      ),
    ].map((item) => {
      return {
        province: item.split("_")[0],
        city: item.split("_")[1],
        tumbon: item.split("_")[2],
        total_all : 0,
        total_target: 0,
        total_oosc: 0,
        total_oosc_percent : '',
        target3_5: 0,
        target6_11: 0,
        target12_14: 0,
        target15_17: 0,
        oosc3_5: 0,
        oosc6_11: 0,
        oosc12_14: 0,
        oosc15_17: 0,
      };
    });
    data.forEach((item) => {
      if (layer === "country") {
        let index = province.findIndex(
          (ele) => ele.province === item.province_name
        );
        province[index]["total_all"] += (item[`year${filter.year}_total_oosc`] + item[`year${filter.year}_total_target`]);
        province[index]["total_target"] += item[`year${filter.year}_total_target`];
        province[index]["total_oosc"] += item[`year${filter.year}_total_oosc`];
        province[index]["target3_5"] +=
          item[`year${filter.year}_age_target_3_5`];
        province[index]["target6_11"] +=
          item[`year${filter.year}_age_target_6_11`];
        province[index]["target12_14"] +=
          item[`year${filter.year}_age_target_12_14`];
        province[index]["target15_17"] +=
          item[`year${filter.year}_age_target_15_17`];
        province[index]["oosc3_5"] +=
          item[`year${filter.year}_oosc_3_5`];
        province[index]["oosc6_11"] +=
          item[`year${filter.year}_oosc_6_11`];
        province[index]["oosc12_14"] +=
          item[`year${filter.year}_oosc_12_14`];
        province[index]["oosc15_17"] +=
          item[`year${filter.year}_oosc_15_17`];
        res = province;
      } else if (layer === "province") {
        let index = city.findIndex(
          (ele) =>
            ele.province === item.province_name && ele.city === item.city_name
        );
        if (city[index]) {
          city[index]["total_all"] += (item[`year${filter.year}_total_oosc`] + item[`year${filter.year}_total_target`]);
          city[index]["total_target"] += item[`year${filter.year}_total_target`];
          city[index]["total_oosc"] += item[`year${filter.year}_total_oosc`];
          city[index]["target3_5"] +=
            item[`year${filter.year}_age_target_3_5`];
          city[index]["target6_11"] +=
            item[`year${filter.year}_age_target_6_11`];
          city[index]["target12_14"] +=
            item[`year${filter.year}_age_target_12_14`];
          city[index]["target15_17"] +=
            item[`year${filter.year}_age_target_15_17`];
          city[index]["oosc3_5"] +=
            item[`year${filter.year}_oosc_3_5`];
          city[index]["oosc6_11"] +=
            item[`year${filter.year}_oosc_6_11`];
          city[index]["oosc12_14"] +=
            item[`year${filter.year}_oosc_12_14`];
          city[index]["oosc15_17"] +=
            item[`year${filter.year}_oosc_15_17`];
          res = city;
        }
      } else if (layer === "city") {
        let index = tumbon.findIndex(
          (ele) =>
            ele.province === item.province_name &&
            ele.city === item.city_name &&
            ele.tumbon ===
            (item.province_id === "10"
              ? "แขวง" + item.tumbon_name
              : item.tumbon_name)
        );
        if (tumbon[index]) {
          tumbon[index]["total_all"] += item[`year${filter.year}_total_oosc`] + item[`year${filter.year}_total_target`];
          tumbon[index]["total_target"] += item[`year${filter.year}_total_target`];
          tumbon[index]["total_oosc"] += item[`year${filter.year}_total_oosc`];
          tumbon[index]["target3_5"] +=
            item[`year${filter.year}_age_target_3_5`];
          tumbon[index]["target6_11"] +=
            item[`year${filter.year}_age_target_6_11`];
          tumbon[index]["target12_14"] +=
            item[`year${filter.year}_age_target_12_14`];
          tumbon[index]["target15_17"] +=
            item[`year${filter.year}_age_target_15_17`];
          tumbon[index]["oosc3_5"] +=
            item[`year${filter.year}_oosc_3_5`];
          tumbon[index]["oosc6_11"] +=
            item[`year${filter.year}_oosc_6_11`];
          tumbon[index]["oosc12_14"] +=
            item[`year${filter.year}_oosc_12_14`];
          tumbon[index]["oosc15_17"] +=
            item[`year${filter.year}_oosc_15_17`];
          res = tumbon;
        }
      }
    });
    res = res.map(item => {
      return {
        ...item, 
        // oosc_percent : ((item.total_oosc/item.total_all)*100).toFixed(2) ,
        total_oosc_percent : `${item.total_oosc.toLocaleString("en-US")} (${((item.total_oosc/item.total_all)*100).toFixed(2)}%)`
      }
    })
    return res;
  };

  const onProcessData = (filter, data) => {
    const _genColorRange = (color_set, value) => {
      color_set = color_set.sort((a, b) => b.value - a.value);
      let color = "";
      for (let i in color_set) {
        if (value > color_set[i].value) {
          color = color_set[i].color;
          break;
        }
      }
      return color;
    };

    let result = [];
    data.forEach((element) => {
      if (
        filter.province &&
        filter.province !== "ทั้งหมด" &&
        !filter.city &&
        !filter.tumbon
      ) {
        if (filter.province === element.province) {
          result.push(element);
        }
      } else if (filter.province && filter.city && !filter.tumbon) {
        if (
          filter.province === element.province &&
          filter.city === element.city
        ) {
          result.push(element);
        }
      } else if (filter.province && filter.city && filter.tumbon) {
        if (
          filter.province === element.province &&
          filter.city === element.city &&
          filter.tumbon === element.tumbon
        ) {
          result.push(element);
        }
      } else {
        result.push(element);
      }
    });

    result.map((element) => {
      if (!isFinite(element["total_oosc"] / element["total_all"])) {
        element["percent"] = 0;
      } else {
        element["percent"] =
          (element['total_oosc'] /
            result.reduce((a, b) => a + b.total_all, 0)) *
          100;
      }
      return element;
    });
    let data_color = [
      {
        color: border_color[0],
        value: Calculate.Percentile(
          90,
          result.map((item) => (item.total_oosc/item.total_all)*100)
        ),
        count: 0,
        text: `มาก`,
      },
      {
        color: border_color[1],
        value: Calculate.Percentile(
          50,
          result.map((item) => (item.total_oosc/item.total_all)*100)
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      {
        color: border_color[2],
        value: Calculate.Percentile(
          10,
          result.map((item) => (item.total_oosc/item.total_all)*100)
        ),
        count: 0,
        text: `ปานกลาง`,
      },
      { color: border_color[3], value: 0, count: 0, text: `น้อย` },
      { color: border_color[4], value: -100, count: 0, text: `น้อย` },
    ];
    result.forEach((element) => {
      element["color"] = _genColorRange(data_color, (element.total_oosc/element.total_all)*100);
      element["percent"] = parseFloat(element["percent"].toFixed(2));
    });
    let res = { data: result, notic: data_color };
    return res;
  };

  const onclickBorder = (layer, filterBorder) => {
    if (layer === "city" && user_info.current === "public-login") return;
    if (layer === "tumbon") return;
    if (!is_Click.current) {
      is_Click.current = true;
      let _filter = { ...filter };
      if (layer === "province" && filterBorder.province) {
        _filter["year"] = filterBorder.year;
        _filter["view"] = "province";
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = null;
        _filter["tumbon_name"] = null;
        _filter["checkbox_side"] = [];
      } else if (layer === "city" && filterBorder.city) {
        _filter["year"] = filterBorder.year;
        _filter["view"] = "city";
        _filter["province_name"] = filterBorder.province;
        _filter["city_name"] = filterBorder.city;
        _filter["tumbon_name"] = null;
        _filter["checkbox_side"] = [];
      }
      setFilter(_filter);
      setTimeout(() => {
        is_Click.current = false;
      }, 1000);
    }
  };

  const onBack = () => {
    let _filter = { ...filter };
    if (_filter.view === "country") return;
    if (_filter.view === "province") {
      _filter["view"] = "country";
      _filter["province_name"] = "ทั้งหมด";
      _filter["city_name"] = null;
      _filter["tumbon_name"] = null;
    } else if (_filter.view === "city") {
      _filter["view"] = "province";
      _filter["city_name"] = null;
      _filter["tumbon_name"] = null;
    }
    setFilter(_filter);
  };

  let tab_content = [
    {
      title: 'ภาพรวม',
      isHide: true,
      content: <Overview data={data_map.length > 0 ? data_map[0].data : []} filter={{...filter}}
        roleActive={user_info.current}
        onUpdateFilter={(filter) => {
          setFilter({ ...filter })
        }}
      />,
    },
  ]

  return (
    <Layoutas Role={["partner", "eef", "public-login"]} isHideFooter>
      <div className="relative overflow-hidden">
        <div className="w-[100vw] h-[100vh]">
          <Mapbox
            id={"map-cct"}
            filter={filter}
            data={data_map}
            className="fullmap-cct-container"
            style={"border_basic"}
            openSideTab={open}
            notic_title={"จำนวนโรงเรียนในประเทศไทย"}
            onclickBorder={onclickBorder}
          />
          {/* <div className="absolute top-[90px] left-2 z-1">
            <LegendCard {...legend_data} />
            <div className="justify-left mt-2 mx-auto">
              {filter.view !== "country" ? (
                <button
                  class="bg-[#fb923c] hover:bg-[#f97316] border-8 border-[#ffedd5] text-white font-bold p-2 rounded-full"
                  onClick={() => onBack()}
                >
                  <ArrowBackIcon sx={{ fontSize: 18, color: "white" }} />{" "}
                  ย้อนกลับ
                </button>
              ) : (
                <></>
              )}
            </div>
          </div> */}
        </div>
        <div className='absolute bottom-[50px] md:bottom-[10px] left-[100px] z-1 flex'>
          <div className="h-full rounded-lg flex flex-col shadow-xl bg-white px-2 py-3">
            <span className="font-bold center mx-1 mb-2">
              สัดส่วนเด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา
            </span>
            <div className="flex">
              <div className="flex flex-col">
                <div class={`h-3 w-8 md:w-16 bg-[#7f1d1d]`}></div>
                <div class="font-bold text-left">มาก</div>
              </div>
              <div className="flex flex-col">
                <div class={`h-3 w-8 md:w-16 bg-[#dc2626]`}></div>
              </div>
              <div className="flex flex-col">
                <div class={`h-3 w-8 md:w-16 bg-[#f87171]`}></div>
              </div>
              <div className="flex flex-col">
                <div class={`h-3 w-8 md:w-16 bg-[#fecaca]`}></div>
                <div class="font-bold text-right">น้อย</div>
              </div>
              <div className="ml-4  flex flex-col">
                <div class={`h-3 w-8 md:w-16 bg-[#94a3b8]`}></div>
                <div class="font-bold text-center">ไม่มีข้อมูล</div>
              </div>
            </div>
          </div>
        </div>


        <Sidetab
          title={<span>แผนที่เด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา <br /> ปีการศึกษา 2566</span>}
          tab={initData ? tab_content : []}
          titleRef={<>
            <div className='ml-4 mb-2'>
              <a
                onClick={() => { setModal(true) }}
                className="font-[22px] mb-6 text-gray-800 underline-offset-auto ">
                <InfoIcon /> แหล่งที่มาของข้อมูล
              </a>
            </div>
            <span className="ml-4 pb-6">*ประมวลผล ณ เดือนพฤศจิกายน 2566</span>
            <Modal
              width={800}
              visible={ismodal}
              // title="แหล่งที่มาของข้อมูล"
              onOk={() => setModal(false)}
              onCancel={() => setModal(false)}
              cancelButtonProps={{ style: { display: "none" } }}
            >
              <div>
                {
                  <div>
                    <span className='text-lg font-bold'>แหล่งที่มาข้อมูลจำนวนเด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา (อายุ 3 - 18 ปี)</span><br />
                    <span className='text-base'>(1) ข้อมูลทะเบียนราษฎร์ ปี 2566</span><br />
                    <span className='text-base'>(2) ข้อมูลจำนวนนักเรียนในทุกสังกัดจาก สป.ศธ. ภาคการศึกษาที่ 1/2566</span><br />
                    <span className='text-base'>(3) นำข้อมูลในข้อที่ (1) หักลบกับ (2) จะได้จำนวนเด็กที่ไม่มีข้อมูลในระบบการศึกษา ปีการศึกษา 2566</span><br />
                    <span className='text-base'>*อายุของเด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา คำนวณจาก วัน/เดือน/ปี เกิด</span>
                  </div>
                }
              </div>
            </Modal>
            {/* <p className="my-0">*ข้อมูลจำนวนเด็กและเยาวชนที่ไม่มีข้อมูลในระบบการศึกษา อายุ 3 - 17 ปี ปีการศึกษา 2566 ได้จาก</p>
            <p className="px-2 my-0">(1) ข้อมูลทะเบียนราษฎร์ ปี 2566</p>
            <p className="px-2 my-0">(2) ข้อมูลจำนวนนักเรียนในทุกสังกัดจาก สป.ศธ. ภาคการศึกษาที่ 1/2566</p>
            <p className="px-2 mt-0">(3) นำข้อมูลในข้อที่ (1) หักลบกับ (2) จะได้จำนวนเด็กที่ไม่มีข้อมูลในระบบการศึกษา ปีการศึกษา 2566</p> */}
          </>}
          // subjectTitleRef={<span>*ประมวลผล ณ เดือนพฤศจิกายน 2566</span>}
          open={open}
          tabvalue={tabvalue}
          setOpen={setOpen}
        />

      </div>
    </Layoutas>
  );
};

export default FullMap;
